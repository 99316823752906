import { createRouter, createWebHistory } from "vue-router";
import AuthService from '@/services/auth.service';

import { useActionCable } from "@/stores/cable.js";
const NotFound = () => import('@/components/layouts/NotFound.vue');
const Index = () => import('@/views/Home.vue');
const About = () => import('@/views/AboutPage.vue');
const Login = () => import('@/views/LoginPage.vue');
const ForgetPassword = () => import('@/views/ForgetPassword.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');

const Logout = () => import('@/views/Logout.vue');
const Signup = () => import('@/views/SignupPage.vue');
const Users = () => import('@/views/users/Index.vue');
const UserShow = () => import('@/views/users/Show.vue');
const MessagesPage = () => import('@/views/conversation/Index.vue');

// Note App imports
const Notes = () => import('@/views/note_app/notes/Index.vue');


// my finance app imports
const ExpenseIndex = () => import('@/views/my_finance_app/expense/Index.vue');
const LoanIndex = () => import('@/views/my_finance_app/loan/Index.vue');

// contact_app
const ContactIndex = () => import('@/views/contact_app/contact/Index.vue');

const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: Index,
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   components: {
  //     default: About,
  //   },
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    components: {
      default: NotFound,
    },
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: Login,
    },
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    components: {
      default: ForgetPassword,
    },
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    components: {
      default: ResetPassword,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    components: {
      default: Signup,
    },
  },
  {
    path: '/users',
    name: 'users',
    components: {
      default: Users,
    },
  },
  {
    path: '/users/:id',
    name: 'user',
    components: {
      default: UserShow,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      default: Logout,
    },
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: MessagesPage,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: "/note_app/notes",
    name: 'notes',
    components: {
      default: Notes
    },
  },
  {
    path: "/my_finance_app",
    children: [
      {
        path: "expenses",
        component: ExpenseIndex,
        name: 'expenses',
        children: [
        ]
      },
      {
        path: "loans",
        component: LoanIndex,
        name: 'loans',
        children: [
        ]
      }
    ]
  },
  {
    path: "/contact_app",
    children: [
      {
        path: "contacts",
        component: ContactIndex,
        name: 'contacts',
        children: [
        ]
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// router.beforeEach(async (to, from, next) => {
//   try {
//     const user = AuthService.getUser();
//     const publicPages = ['/login', '/signup', '/forget_password', '/reset_password', '/'];
//     const authRequired = !publicPages.includes(to.path);

//     if (authRequired && !user) {
//       AuthService.clearCache();
//       next('/login');
//     } else if (to.path === '/login' && user) {
//       next('/');
//     } else {
//       next();
//     }
//   } catch (e) {
//     console.log(e)
//   } finally {
//     const elem = document.querySelector("#mainLoader");
//     elem.style.display = "none";
//   }
// });

router.beforeEach(async (to, from, next) => {
  try {
    // Retrieve the current user
    const user = AuthService.getUser();
    const publicPages = ['/login', '/signup', '/forget_password', '/reset_password', '/'];
    const authRequired = !publicPages.includes(to.path);

    // Extract the user's applications
    const userApplications = user?.applications || [];

    // Define protected routes and their required applications
    const protectedRoutes = {
      '/my_finance_app/expenses': 'MyFinanceApp',
      '/my_finance_app/loans': 'MyFinanceApp',
      '/note_app/notes': 'NoteApp',
      '/users': 'Users',
      // '/users/:id': 'Users',
      // '/users/:id/edit': 'Users',
      // Add other routes as necessary
    };

    // Check if the route is protected and if the user has the required application
    const requiredApp = Object.keys(protectedRoutes).find(route => to.matched.some(record => record.path === route));
    const hasAccess = !requiredApp || userApplications.includes(protectedRoutes[requiredApp]);
    const { initActionCable } = useActionCable();
    initActionCable();

    // Handle route navigation logic
    if (authRequired && !user) {
      AuthService.clearCache();
      next('/login');
    } else if (to.path === '/login' && user) {
      next('/');
    } else if (requiredApp && !hasAccess) {
      router.push({ name: 'not_found' })
    } else {
      next();
    }
  } catch (e) {
    console.error('Routing error:', e);
    next(false); // Cancel the navigation in case of an error
  } finally {
    // Hide the main loader after navigation is processed
    const elem = document.querySelector("#mainLoader");
    if (elem) elem.style.display = "none";
  }
});


export default router;


